<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <div class="esmp-textarea">
        <textarea
          class="esmp-textarea__input"
          v-model="localValue"
          :placeholder="placeholder"
          @input="onInput"
        />
      </div>
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';

export default {
  name: 'HmTextArea',
  mixins: [uid],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val.target.value);
    },
  },
};
</script>
<style lang="scss">
.esmp-textarea {
  &__input {
    min-height: 100px;
    background: #f3f3f4;
    padding: 3px 12px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    color: #101828;
    font-size: 16px;
    line-height: 24px;
    resize: vertical;
    border: none;
  }
}
</style>
