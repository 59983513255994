// TODO: переделать на надежный метод, например от lodash

const uid = {
  data() {
    return {
      vid: String(+new Date()),
    };
  },
};

export default uid;
