<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ placeholder }}
        <span v-if="required">*</span>
      </div>
      <div class="hm-form__field">
        <esmp-upload
          type="drag"
          v-model="localValue"
        />
      </div>
      <div class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'HmUploader',
  mixins: [uid],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: 'Не более 20 мбайт',
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        const filesWithId = newValue.map((file) => {
          const newFile = file;

          newFile.id = uniqueId('file-');

          return file;
        });

        this.$emit('input', filesWithId);
      },
    },
  },
};
</script>
<style lang="scss">
.hm-form {
  &__field {
    display: flex;
    align-items: center;

    .esmp-upload {
      width: 100%;
    }

    .esmp-icon {
      flex-shrink: 0;
    }
  }
}
</style>
