<template>
  <div class="modal-ticket-reopen">
    <esmp-modal
      v-model="show"
      title="Введите причину возврата заявки в работу"
      :width="800"
      class-name="modal-ticket-reopen"
    >
      <hm-text-area
        placeholder="Причина возврата заявки в работу"
        v-model="comment"
      />

      <hm-uploader
        name="Файл"
        rules="size:147456000"
        v-model="files"
      />
      <template #footer>
        <esmp-button @click="reopenHandler">
          Вернуть
        </esmp-button>
        <esmp-button @click="closeModal">
          Закрыть
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';
import HmUploader from '@/components/hm-form/controls/HmUploader.vue';

export default {
  name: 'ModalTicketReopen',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      required: true,
    },
    ticketId: {
      type: String,
      required: true,
    },
    isApproval: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HmUploader,
    HmTextArea,
  },
  data() {
    return {
      comment: '',
      files: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    async reopenHandler() {
      if (this.comment) {
        this.setLoading({ key: 'app', value: true });
        const data = new FormData();
        data.append('comment', this.comment);
        data.append('source', this.sourceSystem);
        this.files.forEach((file) => {
          data.append('attachments', file, file.name);
        });
        this.$API.ticket.reopenTicket(this.ticketId, data)
          .then(() => {
            this.closeModal();
            this.$EsmpNotify.$show('Заявка возвращена в работу', 'success');

            this.$emit('fetch-tickets');
          })
          .catch((err) => err);
        this.setLoading({ key: 'app', value: false });
      } else {
        this.$EsmpNotify.$show('Введите комментарий', 'error');
      }
    },
    closeModal() {
      this.comment = '';
      this.files = [];
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
</style>
